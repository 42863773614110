import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import sampleSize from 'lodash/sampleSize'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { parseHtml } from 'contentful-html-rich-text-converter'
import { GatsbyImage } from 'gatsby-plugin-image'
import HelmetHelper from '@components/HelmetHelper'
import TableOfContents from '@components/TableOfContent'
import Layout from '@components/Layout'
import BottomCta from '@components/BottomCta'
import Typography from '@atoms/Typography'
import { Styles } from '@constants'
import { getDescriptionSubstring } from '@utils'
import SideTrial from '@components/custom/SideTrial'
import ArticlePreview from '@components/ArticlePreview'
import getOptions from './getOptions'

const imgClass = 'my-10'

const BlogPostTemplate = (props) => {
  const post = get(props, 'data.contentfulBlogPost')

  const allSimilarPosts = get(props, 'data.similarPosts.nodes')
  const sampleSimilarPosts = sampleSize(allSimilarPosts, 3)

  const options = post.content
    ? getOptions(post.content.references, imgClass)
    : getOptions()

  const bodyRaw = post.content
    ? JSON.parse(post.content.raw)
    : parseHtml(post.body.childMarkdownRemark.html)

  const header = (
    <div className="section-blog-header mx-auto">
      <div className="flex items-center pb-6 sm:pb-8">
        <div>
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mt-5 mb-5 leading-normal">
            {post.title}
          </h1>

          <div className="flex items-center space-x-6 sm:space-x-10">
            <div className="flex items-center">
              <GatsbyImage
                className="h-6 w-6 rounded-full mr-2"
                alt={post.author.name}
                title={post.author.name}
                image={post.author.image.gatsbyImageData}
              />
              <div className="text-xm sm:text-md text-gray-700">
                {post.author.name}
              </div>
            </div>
            <div className="text-xm sm:text-md text-gray-700">
              {post.publishDate}
            </div>
            <div className="text-xm sm:text-md text-gray-700 capitalize">
              {get(post, 'tags[0]')}
            </div>
          </div>
        </div>
      </div>

      <GatsbyImage
        className="hidden sm:flex mx-auto max-w-3xl rounded-xl"
        alt={post.title}
        title={post.title}
        image={post.heroImage.gatsbyImageData}
      />
    </div>
  )

  const body = (
    <div className="section-content section-blog-content max-w-screen-sm w-full mx-auto -mt-4 py-6 md:py-0">
      {!!bodyRaw && documentToReactComponents(bodyRaw, options)}
    </div>
  )

  const imageSrc = get(post, 'heroImage.gatsbyImageData.images.fallback.src')

  return (
    <Layout
      location={props.location}
      disableDefaultImage
      disableLinks
      disableStickyNavbar
      customContainer={Styles.blogContainer}
    >
      <HelmetHelper
        title={post.metaTitle}
        description={getDescriptionSubstring(post.description.description)}
        image={imageSrc}
        type="article"
      >
        <meta property="og:type" content="article" />
      </HelmetHelper>
      <div className="md:pt-10 pb-0 lg:px-6">
        {header}
        <div className="lg:flex lg:py-8">
          <TableOfContents body={bodyRaw} disableSmallHeading />
          {body}
          <SideTrial />
        </div>
      </div>

      <div className="max-w-6xl mx-auto py-14">
        <div className="mb-6">
          <Typography.H2 component="p">Related posts</Typography.H2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-16 py-5">
          {sampleSimilarPosts.map((similarPost) => (
            <div key={post.slug}>
              <ArticlePreview data={similarPost} />
            </div>
          ))}
        </div>
      </div>

      <BottomCta />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      metaTitle
      author {
        name
        title
        company
        image {
          gatsbyImageData(placeholder: BLURRED, width: 24, height: 24)
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            id
            contentful_id
            title
            file {
              contentType
              url
            }
            gatsbyImageData(
              placeholder: BLURRED
              resizingBehavior: SCALE
              layout: FULL_WIDTH
              height: 357
              width: 640
            )
          }
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          height: 280
          width: 500
        )
      }
      tags
      description {
        description
      }
    }
    similarPosts: allContentfulBlogPost(filter: { slug: { ne: $slug } }) {
      nodes {
        title
        author {
          name
          title
          company
          image {
            gatsbyImageData(placeholder: BLURRED, width: 60, height: 60)
          }
        }
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            height: 110
            width: 200
          )
        }
        description {
          description
        }
      }
    }
  }
`
